import React, {useEffect, useRef} from "react"
import { Link } from "gatsby"
import Img from "gatsby-image";
import styled from "styled-components"
import style from "../styles/main"
import { useSpring, animated } from 'react-spring'
import Layout from "../components/layout"
import Background from "../components/background"
import Stripe from "../components/stripe"
import PageMetadata from "../components/SEO"

const scale = s => `scale(${s})`
const calc = o => `translateY(${o}px)`;

const Row = styled.div`
display: block;
position: relative;
width: 100%;
`;

const ImgWrapper = styled(animated.div)`
  position: relative;
  z-index: 100;
  height: 100%;
  @media all and (min-width: ${style.mediaMinWidth}px) {
    margin-top: ${props => props.margin}vh;
    left: ${props => props.left}%;
    width: ${props => props.width}%;
    max-width: ${props => props.width}%;
  }
  @media not all and (min-width: ${style.mediaMinWidth}px) {
    padding-top: 28px;
    width: calc(100% - 56px);
    left: 28px;
  }
`;

const Projects = styled.div`
  position: relative;
  margin: auto;
  max-width: 100vw;
  overflow-x: hidden;
  overflow-y: hidden;
  padding-bottom: 25px;
`;

export default ({ data }) => {
  const stripes = data.allYaml.edges.map((edge) => {
    const s = edge.node.stripe
    if (s == null || typeof s == 'undefined') {
      return null
    }
    const ref = useRef();
    const [{ offset }, set] = useSpring(() => ({ offset: 0 }));

    const handleScroll = () => {
      if (ref.current) {
        const top = ref.current.getBoundingClientRect().top;
        const bottom = ref.current.getBoundingClientRect().bottom;
        if (window) {
          if (top < window.innerHeight && bottom > 0) {
            const offset = 0.2 * window.pageYOffset
            set({offset})
          }
        }
      }
    };

    useEffect(() => {
      if (window) {
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }
    });
    return <Stripe ref={ref} l={s.left} w={s.width} h={s.height} m={s.margin_top} style={{transform: offset.interpolate(calc)}}/>
  })

  const rows = data.allYaml.edges.map((edge) => {
    const image = edge.node.main_image
    const [p, set] = useSpring(() => ({ scale: 1.0 }))
    return (
      <>
      <Row>
        <ImgWrapper
          margin={image.top_offset}
          left={image.left}
          width={image.width}
          onMouseOver={() => set({ scale: 1.05 })}
          onFocus={() => set({ scale: 1.05 })}
          onMouseLeave={() => set({ scale: 1.0 })}
          onFocusOut={() => set({ scale: 1.0 })}
          style={{ transform: p.scale.interpolate(scale) }}
        >
          <Link to={edge.node.fields.slug}>
            <Img
              fluid={image.file.childImageSharp.fluid}
              title={image.name}
              alt={image.name}
            />
          </Link>
        </ImgWrapper>
      </Row>
      </>
    )
  })
  return (
    <>
      <PageMetadata />
      <Layout initialHeight={0}>
        <Background>{stripes}</Background>
        <Projects>{rows}</Projects>
      </Layout>
    </>
  )
}

export const query = graphql`
  query {
    allYaml(sort: {fields: index, order: ASC}) {
      edges {
        node {
          fields {
            slug
          }
          stripe {
            width
            left
            height
            margin_top
          }
          main_image {
            name
            file {
              id
              publicURL
              childImageSharp {
                fluid(maxWidth: 1600) {
                    ...GatsbyImageSharpFluid
                }
              }
            }
            top_offset
            left
            width
          }
        }
      }
    }
  }
`
